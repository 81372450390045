import {t, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {Container, Divider, Grid, Typography} from '@mui/material'
import {NextPage} from 'next'

import {useUser} from 'contexts/user'
import {loginRequired} from 'core/auth'
import {HomeCard, HomeCardProps} from 'core/components/cards/home-cards'

const Home: NextPage = () => {
    useLingui()
    const {user} = useUser()
    const cards: HomeCardProps[] = [
        {
            title: t`Quoting`,
            link: '/quotes',
            children: t`Create quotes, generate sales, create accounts.`,
            color: '#5C5A89',
            permission: user?.permissions.viewQuotes,
        },
        {
            title: t`Supply`,
            link: '/supply-orders',
            children: t`Follow the status of the supply orders.`,
            color: '#456C7D',
            permission: user?.permissions.viewSupplyOrders,
        },
        {
            title: t`Warranty claim`,
            link: '/warranty-claims',
            children: t`Create warranty claims.`,
            color: '#9FC569',
            permission: user?.permissions.viewWarrantyClaims,
        },
        {
            title: t`Analytics`,
            link: '/analytics',
            children: t`Statistics of quotes, sales, potential sales, supply orders, etc.`,
            color: '#393F3B',
            permission: user?.permissions.viewAnalytics,
        },
        {
            title: 'ECNs',
            link: '/parts-management',
            children: t`Engineering change notifications.`,
            color: '#268EF0',
            permission: user?.permissions.viewEcns,
        },
    ]

    return (
        <Container maxWidth='lg'>
            <Grid
                container={true}
                spacing={4}>
                <Grid
                    item={true}
                    xs={12}
                    mt={4}>
                    <Typography
                        variant='h5'
                        color='primary'>
                        <Trans>We welcome you to Precision Planting!</Trans>
                    </Typography>
                </Grid>
                <Grid
                    item={true}
                    xs={12}>
                    <Divider />
                </Grid>
                <Grid
                    item={true}
                    xs={12}>
                    <Typography variant='subtitle1'>
                        <Trans>Chose one access to continue.</Trans>
                    </Typography>
                </Grid>
                {cards.map((card, index) => (
                    <HomeCard
                        key={index}
                        gridProps={{xs: 12, sm: 4, md: 3}}
                        {...card}
                    />
                ))}
            </Grid>
        </Container>
    )
}

export default loginRequired(Home)
