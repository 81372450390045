/* eslint-disable react/display-name */
import {useRouter} from 'next/router'
import {useEffect} from 'react'

import {useUser} from 'contexts/user'
import {Forbidden} from 'core/exceptions'
import {UserFragment} from 'generated/graphql'
import {LOGIN_URL} from 'settings'

type UserCheck = (user: UserFragment) => boolean

export const userCheckRequired = (check: UserCheck) => (Component: React.ComponentType) => () => {
    const router = useRouter()
    const {user} = useUser()
    useEffect(() => {
        if (typeof window === 'undefined') return
        if (!user) {
            router.push(`${LOGIN_URL}?next=${router.asPath}`)
        } else if (!check(user!)) {
            throw new Forbidden()
        }
    }, [user])

    return user ? <Component /> : null
}

export const loginRequired = userCheckRequired(user => Boolean(user))
export const checkForPermission = (
    permission: keyof UserFragment['permissions'] | (keyof UserFragment['permissions'])[],
) =>
    userCheckRequired(
        user =>
            Boolean(user) &&
            (Array.isArray(permission)
                ? permission.every(p => Boolean(user.permissions[p]))
                : Boolean(user.permissions[permission])),
    )
