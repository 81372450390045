import {Card, CardContent, CardHeader, Grid, GridProps, TypographyProps} from '@mui/material'
import Link from 'next/link'
import React, {FC} from 'react'
import {makeStyles} from 'tss-react/mui'

export type HomeCardProps = {
    color?: string
    title?: React.ReactNode
    titleProps?: TypographyProps
    link: string
    children?: React.ReactNode
    permission?: boolean
    gridProps?: GridProps
}

const useStyles = makeStyles<{color?: string}>()((theme, props) => ({
    root: {
        'height': '100%',
        'minHeight': '200px',
        'paddingTop': theme.spacing(2),
        'display': 'flex',
        'flexDirection': 'column',
        'justifyContent': 'space-around',
        'transition': theme.transitions.create('transform', {
            duration: '0.15s',
        }),
        '&:hover': {
            transform: 'scale3d(1.1, 1.1, 1)',
            cursor: 'pointer',
            border: `1px solid ${props.color}`,
        },
    },
}))

export const HomeCard: FC<HomeCardProps> = ({title, titleProps, children, color, link, permission, gridProps}) => {
    const {classes} = useStyles({color})
    if (!permission) return null
    return (
        <Grid
            item={true}
            {...gridProps}>
            <Link
                href={link}
                passHref={true}
                style={{textDecoration: 'none'}}>
                <Card className={classes.root}>
                    {title && (
                        <CardHeader
                            title={title}
                            titleTypographyProps={titleProps ? titleProps : {align: 'center', sx: {color}}}
                        />
                    )}
                    <CardContent>{children}</CardContent>
                </Card>
            </Link>
        </Grid>
    )
}
